import React from "react";
import { toast } from "react-toastify";
import maskData from "../masks.json";
import dayjs from 'dayjs';
import { languageOptions } from '../language';
import axios from 'axios';

export const maskIsMagnetic = (display_name) => {
  let isMagnetic = false;
  for (let category in maskData) {
    maskData[category].forEach(mask => {
      if (mask.name === display_name) {
        isMagnetic = mask.is_magnetic;
      }
    })
  }
  return isMagnetic;
};

export const getMaskFrameSize = (name) => {
  for (let category in maskData) {
    for (let idx in maskData[category]) {
      let mask = maskData[category][idx];
      if (mask.name === name) {
        return mask.frame_sizes ? Object.keys(mask.frame_sizes).map((size) => {
          return { label: size, value: size }
        }) : [{ label: "Standard", value: "Standard" }];
      }
    }
  }
};

export const getMaskCushionSize = (name) => {
  for (let category in maskData) {
    for (let idx in maskData[category]) {
      let mask = maskData[category][idx];
      if (mask.name === name) {
        return Object.keys(mask.cushion_sizes).map((size) => {
          return { label: size, value: size }
        })
      }
    }
  }
};

export const getMaskNumCushionSizes = (name) => {
  for (let category in maskData) {
    for (let idx in maskData[category]) {
      let mask = maskData[category][idx];
      if (mask.name === name) {
        return Object.keys(mask.cushion_sizes).length;
      }
    }
  }
};

export const getMaskDisplayName = (name) => {
  for (let category in maskData) {
    for (let idx in maskData[category]) {
      let mask = maskData[category][idx];
      if (mask.name === name) {
        return mask.mfr_display_name + " " + mask.display_name;
      }
    }
  }
  return name;
};

export const getMfrDisplayName = (company) => {
  for (var category in maskData) {
    for (var idx in maskData[category]) {
      var mask = maskData[category][idx];
      if (mask.company === company) {
        return mask.mfr_display_name;
      }
    }
  }
  return "Unknown";
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phoneNumber) => {
  return phoneNumber.length > 0;
};

export const validatePatientID = (id) =>
  id === encodeURI(id) &&
  id.trim().length > 0 &&
  id.indexOf("/") <= -1 &&
  id.indexOf("\\") <= -1;

export const capitalize = (s) => {
  if (null === s) return s;
  if (typeof s !== "string") s = s.toString();
  if (s.length > 0) s = s.charAt(0).toUpperCase() + s.slice(1);
  return s;
};

export const getLangType = () => {
  return "en";
};

export const formatDateToString = (date, format = null) => {
  var day = "" + date.getDate();
  var month = "" + (date.getMonth() + 1);
  var year = date.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (format == 'yyyy-mm-dd') return [year, month, day].join("-");
  else if (format == 'mm/dd/yyyy') return [month, day, year].join("/");
  else return [month, day, year].join("-");
};

export const requiredLabel = (label = "") => {
  if (label) {
    return (
      <React.Fragment>
        {label} <span className="text-danger">*</span>
      </React.Fragment>
    );
  }
  return <span className="text-danger">*</span>;
};

export const displayFormError = (errorMessage = "") => {
  if (errorMessage) {
    return (
      <div
        className="text-danger"
        style={{
          width: "100%",
          marginTop: ".25rem",
          fontSize: "80%",
        }}
      >
        {errorMessage}
      </div>
    );
  }
  return null;
};

// *getPressureOptions returns options for Pressure field
export const getPressureOptions = () => {
  let pressureOptions = [];
  for (let range = 5; range <= 20; range++) {
    pressureOptions = [...pressureOptions, { label: range, value: range }];
  }

  return [
    { label: "Auto with max pressure <16", value: "<16" },
    { label: "Auto with max pressure >=16", value: ">=16" },
    ...pressureOptions,
    { label: ">20", value: ">20" },
  ];
};

// *userPermission
export const userPermission = (currentUser = null, permission = null) => {
  if (currentUser && permission) {
    if (Array.isArray(permission) && permission.length > 0) {
      let access = false;
      for (let index = 0; index < permission.length; index++) {
        if (currentUser?.[permission[index]]) {
          access = true;
          break;
        }
      }
      return access;
    }
    return currentUser?.[permission] || false;
  }
  return false;
};

export const isTechUser = (currentUser = null) => {
  if (currentUser) {
    const isTech =
      !currentUser?.isSuperAdministrator &&
      !currentUser?.isAdministrator &&
      !currentUser?.isManager &&
      !currentUser?.isClinician &&
      !currentUser?.isReadOnly;

    return isTech;
  }
  return false;
};

export const isOnlyClinician = (currentUser = null) => {
  if (currentUser) {
    const isOnlyClinician =
      currentUser?.isClinician &&
      !currentUser?.isSuperAdministrator &&
      !currentUser?.isAdministrator &&
      !currentUser?.isManager &&
      !currentUser?.isReadOnly;

    return isOnlyClinician;
  }
  return false;
};

// *isValidArray returns passed data is valid array or not
export const isValidArray = (data) => {
  return data && Array.isArray(data) && data.length > 0;
};

// * toast alert
export const toastSuccess = (message) => {
  toast.success(message);
};

export const toastError = (message) => {
  toast.error(message);
};

// * formatDate format knowledge base section date
export const formatDate = (date, format = null) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (format === 'mm-dd-yyyy') return [month, day, year].join("-").replace(/-/g, "/");
  if (format === 'MM-DD-YYYY hh-mm-ss') {
    let hours = d.getHours()
    let minute = d.getMinutes()
    let second = d.getSeconds()
    if (`${hours}`.length < 2) hours = "0" + hours;
    if (`${minute}`.length < 2) minute = "0" + minute;
    if (`${second}`.length < 2) second = "0" + second;
    return `${[month, day, year].join("-").replace(/-/g, "/")}  ${[hours, minute, second].join(":")}`;
  }
  return [year, month, day].join("-");
};

// * patientDetailDate format patient detail date
export const patientDetailDate = (date = '') => {
  if (date) {
    try {
      return new Date(date?.replace(/-/g, "/")).toLocaleString();
    } catch (error) {
      return ''
    }
  }
  return ''
}

export const convertToCustomerTimezone = (dateString, timezone = "America/Chicago") => {
  // Converts UTC date to customerTimezone
  if (dateString) {
    const date = new Date(dateString + "Z"); // Assumes UTC
    return date.toLocaleString("en-US", { timeZone: timezone });
  }
}

export const isMobile = () => {
  if (window) {
    return window?.matchMedia(`(max-width: 599px)`).matches;
  }
  return false;
}

export const reformatDOB = (dob) => {
  if (dob) {
    if (dob.includes('-')) {
      const [year, month, day] = dob.split('-')
      return `${month}/${day}/${year}`
    } return dob;
  } return
}

export const throttle = (cb, delay = 1000) => {
  let shouldWait = false
  let waitingArgs
  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false
    } else {
      cb(...waitingArgs)
      waitingArgs = null
      setTimeout(timeoutFunc, delay)
    }
  }
  return (...args) => {
    if (shouldWait) {
      waitingArgs = args
      return
    }
    cb(...args)
    shouldWait = true
    setTimeout(timeoutFunc, delay)
  }
}

export const subdomain = window.location.host.split('.')[0];
var hostUrl = window.location.host

export const formatPhoneNumberUS = (phoneNumber) => {
  let bareNumber;
  if (phoneNumber && phoneNumber.includes('+1') && phoneNumber.length === 12) {
    bareNumber = phoneNumber.slice(2)
    return `(${bareNumber.slice(0, 3)}) ${bareNumber.slice(3, 6)}-${bareNumber.slice(6, 10)}`
  }
  // Numbers without +1
  else if (phoneNumber && !phoneNumber.includes('+1') && phoneNumber.length === 10) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }
  return phoneNumber
}

export const dataTableThemeConfig = {
  text: {
    primary: 'rgba(0,0,0,.9)',
  },
  background: {
    default: 'var(--color-extreme-light-blue)',
  },
  striped: {
    default: 'white',
    text: 'rgba(0,0,0,.9)',
  },
  context: {
    background: 'var(--color-extreme-light-blue)',
    text: 'rgba(0,0,0,.9)',
  },
  divider: {
    default: 'rgba(0,0,0,.2)',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}

export const roleNames = {
  readonly: 'Read-Only',
  tech: 'Tech',
  clinician: 'Clinician',
  manager: 'Manager',
  administrator: 'Administrator',
  super_administrator: 'Super-Administrator'
}

// If you want to change this list, remember to change it on patient Tools as well.
export const magneticImplantsListEng = [
  'Pacemakers',
  'Implantable cardioverter defibrillators',
  'Metallic stents (such as aneurysm, coronary, tracheobronchial, and biliary)',
  'Neurostimulators (such as hypoglossal nerve stimulators)',
  'Magnetic metallic implants, electrodes, and valves placed in upper limbs, torso, neck, or head',
  'Cerebral spinal fluid shunts (such as ventriculoperitoneal shunt)',
  'Aneurysm clips',
  'Embolic coils',
  'Intracranial aneurysm intravascular flow disruption devices',
  'Metallic cranial plates, screws, burr hole covers, and bone substitute devices',
  'Ocular implants (such as glaucoma implants and retinal implants; intraocular lenses placed during cataract surgery are not impacted)',
  'Certain contact lenses with metal',
  'Implants to restore hearing or balance that have an implanted magnet (such as cochlear implants, implanted bone conduction hearing devices, and auditory brainstem implants) ',
  'Magnetic denture attachments',
  'Implantable ports and pumps (such as insulin pumps)',
  'Metallic gastrointestinal clips',
  'Certain metallic joint replacements',
  'Devices labeled as Magnetic Resonance (MR) Unsafe',
  'Magnetic metallic implants not labeled for MR or not evaluated for safety in a magnetic field',
  'Metallic splinters in the eye',
  'Metallic shrapnel in the body',
  'Hypoglossal Nerve Stimulators'
]

export const getCushionSizesByName = (maskName) => {
  if (maskName) {
    if (maskName === 'Other') {
      // Default cushion sizes
      return [
        { value: 'XS', label: 'XS' },
        { value: 'S', label: 'S' },
        { value: 'SW', label: 'SW' },
        { value: 'M', label: 'M' },
        { value: 'MW', label: 'MW' },
        { value: 'L', label: 'L' },
        { value: 'W', label: 'W' },
        { value: 'XL', label: 'XL' },
        { value: 'Other', label: 'Other' }
      ]
    } else {
      // Selected mask cushion sizes
      const result = [];
      for (const category in maskData) {
        // Loop through each mask in the current category
        maskData[category].forEach((mask) => {
          // Check if the mask name matches the input
          if (mask.name === maskName) {
            // Loop through the cushion sizes and add them to the result array
            Object.keys(mask.cushion_sizes).map(size => {
              result.push({ label: size, value: size })
            })
          }
        });
      } return result;
    }
  } else {
    return [];
  }
}

export const validNumberInput = (event) => {
  // Validate only numbers [0-9]. Used on Input's onKeyDown property
  const { key } = event;
  const isValidKey = (/^[0-9]$/.test(key) || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(key));

  return isValidKey;
}

export const getStatusStylings = (status, row) => {
  const langType = getLangType();
  let classname = 'fas fa-clock';
  let color = 'info';
  let info = languageOptions.ptWaiting[langType];
  if (status.includes('RX - Processed')) {
    classname = 'fas fa-check';
    color = 'success';
    info = languageOptions.ptRXProcessed[langType];
  }
  else if (status.includes('RX - Completed')) {
    classname = 'fas fa-smile';
    color = 'primary';
    info = languageOptions.ptRXCompleted[langType];
  }
  else if (status.includes('Recall - Completed')) {
    classname = 'fas fa-smile';
    color = 'primary';
    info = languageOptions.ptRecallCompleted[langType];
  }
  else if (status.includes('RX')) {
    classname = 'fas fa-prescription-bottle-alt';
    color = 'info';
    info = 'RX';
  }
  else if (status.includes('Processed')) {
    classname = 'fas fa-check';
    color = 'success';
    info = languageOptions.ptProcessed[langType];
  }
  else if (status.includes('Delivered')) {
    classname = row.inviteChannel === 'invite_email' ? 'fas fa-envelope' : 'fas fa-sms';
    color = 'warning';
    info = languageOptions.ptDelivered[langType];
  }
  else if (status.includes('Complete')) {
    classname = 'fas fa-smile';
    color = 'primary'
    info = languageOptions.ptCompleted[langType];
  }
  else if (status.includes('Error')) {
    classname = 'fa fa-exclamation-triangle';
    color = 'danger';
    info = languageOptions.ptScanError[langType];
  }
  else if (status.includes('Failed')) {
    classname = row.inviteChannel === 'invite_email' ? 'fas fa-envelope' : 'fas fa-sms';
    color = 'danger';
    info = languageOptions.ptFailed[langType];
  }
  else if (status.includes('Expired')) {
    classname = 'fas fa-hourglass-end';
    color = 'secondary';
    info = languageOptions.ptExpired[langType];
  }
  else if (status.includes('Voided')) {
    classname = 'fas fa-ban';
    color = 'secondary';
    info = languageOptions.ptVoided[langType];
  }
  else if (status.includes('Unsubscribed')) {
    classname = row?.inviteChannel === 'invite_email' ? 'fas fa-envelope' : row?.inviteChannel === 'invite_text' ? 'fas fa-sms' : 'fa fa-stop-circle';
    color = 'danger';
    info = languageOptions.ptUnsubscribed[langType];
  }
  return { classname, color, info }
}

/**
 * Extract the root domain.
 * @returns {string} The root domain.
 * @example // on http://test1.example.com:8000/login 
 * currentUrl = getCurrentDomain(); // example.com
 */
export const getCurrentDomain = () => {
  return window.location.hostname.split('.').slice(-2).join('.');
};


/**
* Check for a single parameter in the URL and returns it. 
* @returns {string|null} The name of first single (no value) parameter found. If not found or too many returns null.
* @example // on test1.example.com/page?param1
currentUrl = getSingleUrlParam()  // param1
// on test1.example.com/page?param1=3
currentUrl = getSingleUrlParam()  // null
// on test1.example.com/page?param1=3&param2=4
currentUrl = getSingleUrlParam()  // null
*/
export const getSingleUrlParam = () => {
  const url = new URL(window.location.href);
  const queryParams = url.searchParams;
  const paramKeys = [...queryParams.keys()];

  if (paramKeys.length === 1) {
    const firstParam = paramKeys[0];
    if (!queryParams.get(firstParam)) {
      return firstParam
    } else return null

  } else return null
}

/**
* Dinamically writes a cookie associated with the document
* @param {string} name cookie name.
* @param {string} value value.
* @param {string} domain domain to place the cookie.
* @param {number} hours cookie expiration time (in hours). If not provided, 'expires' attribute is not specified and cookie will expire at the end of the session. 
 @example // cookie: "mycookie=example;domain=.example.com"
writeCookie('myCookie','example','example.com') 
*/
export const writeCookie = (name, value, domain, hours = null) => {

  if (domain.includes('localhost')) {
    localStorage.setItem(name, value)
  } else {
    var expires = "";
    if (hours) {
      var date = new Date();
      date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; domain=" + domain;
  }
}

/**
* Reads a cookie value by its name and checks if is expired.
If cookie has expired will be removed.
* @param {string} name cookie name.
* @returns {string|null} cookie value if exists or expired
* @example // cookie: "mycookie=example;expires='today'"
myCookie = readCookieValue('myCookie') // 'example'
myOtherCookie = readCookieValue('myOtherCookie') // null

// cookie: "mycookie=example;expires='yesterday'"
myCookie = readCookieValue('myCookie') // null and remove myCookie
*/
export const readCookieValue = (name) => {

  let domain = getCurrentDomain()

  if (domain.includes('localhost')) {
    return localStorage.getItem(name)
  } else {
    const cookieArray = document.cookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      const cookie = cookieArray[i].trim();
      const [cookieName, cookieValue] = cookie.split('=').map((c) => c.trim());

      if (cookieName === name) {
        // Extract expiration date from the cookie string
        const cookieParts = cookie.split(';');
        const expiresPart = cookieParts.find(part => part.trim().startsWith('expires='));
        const expires = expiresPart ? new Date(expiresPart.split('=')[1].trim()) : null;

        // Cookie has expired
        if (expires && expires < new Date()) {
          return null;
        }
        return cookieValue
      }
    }

    return null;
  }
};


/**
 * Updates the 'expires' attribute of a cookie.
 *
 * @param {string} name The name of the cookie to update.
 * @param {number} domain domain to place the cookie.
 * @param {number} hours The new expiration time (in hours).
 */
export const updateCookieExpiration = (name, domain, hours) => {
  const existingCookieValue = readCookieValue(name);
  if (existingCookieValue !== null) {
    writeCookie(name, existingCookieValue, domain, hours)
  }
}

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const calculateStartDate = (freq, freqType) => {
  const today = dayjs();
  let lastMonday = today.day(1) // 1 is code for Monday

  if (freqType === 'day') {
    const startDate = today.subtract(freq - 1, 'day');
    return startDate.format('YYYY-MM-DD');
  }
  else if (freqType === 'week') {
    const todaysDayCode = today.day() // Returns the code for today
    if (todaysDayCode === 0) {
      lastMonday = today.subtract(today.day() + 6, 'days');
    }
    const startDate = lastMonday.subtract(freq - 1, 'week');
    return startDate.format('YYYY-MM-DD');
  }
  else if (freqType === 'month') {
    const startOfMonth = today.startOf('month');
    const monthYear = startOfMonth.format('YYYY-MM-DD');
    const monthYearSubtracted = startOfMonth.subtract(freq - 1, 'month').format('YYYY-MM-DD');
    const startDate = monthYear === monthYearSubtracted ? startOfMonth : startOfMonth.subtract(freq - 1, 'month');
    return startDate.format('YYYY-MM-DD');
  }
  else {
    console.error('Unknown freqType:', freqType);
    throw new Error(`Unknown freqType: ${freqType}`);
  }
};

export const calculateFrequency = (startDate, endDate) => {
  const dayDiff = dayjs(endDate).diff(dayjs(startDate), 'day')
  // const weekDiff = dayjs(endDate).diff(dayjs(startDate), 'week')
  const weekDiff = Math.ceil(dayDiff / 7);
  // const monthDiff = dayjs(endDate).diff(dayjs(startDate), 'month')
  const monthDiff = Math.ceil(dayDiff / 30);
  if (dayDiff <= 13) return { freqType: 'day', freq: dayDiff }
  else if (dayDiff > 13 && dayDiff <= 84) return { freqType: 'week', freq: weekDiff }
  else if (dayDiff > 84) return { freqType: 'month', freq: monthDiff }
}

const axiosInstance = axios.create({ baseURL: '' })

axiosInstance.interceptors.request.use((config) => {
  const token = readCookieValue('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config
}, (error) => Promise.reject(error))

export const sendPostRequest = (url, data) => {
  return axiosInstance.post(url, data);
};

export const sendPutRequest = (url, data) => {
  return axiosInstance.put(url, data);
};

export const sendGetRequest = (endpoint, cancelToken, onSuccess, onError) => {
  return axiosInstance.get(endpoint, cancelToken || {})
    .then(onSuccess)
    .catch(onError);
};

export const isMicrosoftRedirecting = () => {
  const URL = window.location.href;
  return URL.includes('code=') && URL.includes('state=');
}

export const timezoneOptions = [
  { 'label': 'New York', 'value': 'America/New_York' },
  { 'label': 'Los Angeles', 'value': 'America/Los_Angeles' },
  { 'label': 'Chicago', 'value': 'America/Chicago' },
  { 'label': 'Denver', 'value': 'America/Denver' },
  { 'label': 'Anchorage', 'value': 'America/Anchorage' },
  { 'label': 'Honolulu', 'value': 'Pacific/Honolulu' },
  { 'label': 'Puerto Rico', 'value': 'America/Puerto_Rico' },
]